import { lazy } from "react";
import { findIndex } from "lodash";
import { store } from "@sparky/framework";
import Registry from "@ui-platform/extensibility";

import type { Dashboard, DashboardContribution } from "./types";
import { MAIN_ROUTE } from "./constants";

export default function getDashboardsFromRegistry(baseRoute = MAIN_ROUTE) {
    const contributedReports = [
        ...Registry.getExtensions("vnr.ia.dashboards"),
        ...Registry.getExtensions("dashboards"),
    ] as DashboardContribution[];

    let dashboards: Dashboard[] = [];

    if (contributedReports?.length > 0) {
        const flattenContributions = contributedReports.flat();
        flattenContributions.forEach(
            ({
                key,
                path,
                subPages,
                isAllowed,
                isLoading,
                component,
                group,
                name,
                description,
                widgets,
                position = ["end"],
                upsell,
            }) => {
                const item = {
                    id: key,
                    baseRoute: baseRoute,
                    path: baseRoute + path,
                    subPages: subPages && subPages.map((p) => baseRoute + p),
                    Component: lazy(component),
                    allowed: typeof isAllowed === "function" ? isAllowed(store.getState()) : true,
                    loading: typeof isLoading === "function" ? isLoading(store.getState()) : false,
                    isAllowed,
                    isLoading,
                    group,
                    name,
                    description,
                    widgets,
                } as Dashboard;

                if (
                    upsell &&
                    typeof upsell.isVisible === "function" &&
                    typeof upsell.component === "function"
                ) {
                    item.upsell = {
                        visible: upsell.isVisible(store.getState()),
                        isVisible: upsell.isVisible,
                        Component:
                            typeof upsell.component().then === "function"
                                ? lazy(upsell.component)
                                : (upsell.component as unknown as React.ComponentType),
                    };
                }

                switch (position[0]) {
                    case "start":
                        dashboards = [item, ...dashboards];
                        break;
                    case "end":
                        dashboards = [...dashboards, item];
                        break;
                    case "before": {
                        let i = findIndex(dashboards, { id: position[1] });
                        if (i === -1) i = dashboards.length; // If target can't be found - push item to the end
                        dashboards = [...dashboards.slice(0, i), item, ...dashboards.slice(i)];
                        break;
                    }
                    case "after": {
                        let i = findIndex(dashboards, { id: position[1] });
                        if (i === -1) i = dashboards.length; // If target can't be found - push item to the end
                        dashboards = [
                            ...dashboards.slice(0, i + 1),
                            item,
                            ...dashboards.slice(i + 1),
                        ];
                        break;
                    }
                }
            },
        );
    }
    return dashboards;
}
